import React from 'react';
import {
  BorderRadius,
  LightBlue,
  MainColour,
  MediumGrey,
} from '../../utils/variables';
import Container from '../Container';
import Col from '../Container/column';
import Row from '../Container/row';
import { AngleRight } from '../Icons/icons';
import Links from '../Links';
import TitleH4 from '../Title/h4';

export default function HomepageLinks({ data }) {
  const { header, button, links } = data;
  return (
    <div
      className="homepageFooterLinks"
      style={{
        marginTop: `60px`,
        backgroundColor: LightBlue,
        padding: `60px 0`,
      }}
    >
      <Container>
        <Row align="flex-end" justify="space-between" rowGap="30px">
          <Col className="headerContent" md={{ width: `auto` }}>
            <TitleH4 size="36px" margin="0px" colour={MainColour}>
              {header.title}
            </TitleH4>
            <p>{header.text}</p>
          </Col>
          <Col md={{ width: `auto` }}>
            <Links
              to={button.link}
              label={button.text}
              className="headerAction"
            >
              {button.text} &gt;
            </Links>
          </Col>
        </Row>
        <div style={{ height: `60px` }} />
        <Row rowGap="15px">
          {links.map((link, i) => {
            const key = `homepageFooterLinks${i}`;
            return (
              <Col className="linkItem" key={key} xl={{ width: `50%` }}>
                <Links to={link.link} label={link.text}>
                  <div
                    className="linkInner"
                    style={{
                      backgroundColor: MediumGrey,
                      padding: `15px 30px`,
                      borderRadius: BorderRadius,
                      display: `flex`,
                      alignItems: `center`,
                      gap: `30px`,
                    }}
                  >
                    <AngleRight color={MainColour} />
                    <span>{link.text}</span>
                  </div>
                </Links>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
