import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { ButtonBase, getWindow } from '@web-passion/uikit';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';
import TitleH1 from '../Title';

import { MainColour } from '../../utils/variables';
import Country from '../Form/fields/country';
import ButtonHidden from '../Button/hidden';
import { FormContext } from '../Form/context';
import { LogoWhite } from '../Header/logo';
import Select from '../Form/fields/select';

export default function HomepageBanner({ data }) {
  const { values } = useContext(FormContext);
  const { windowMD, windowSM } = getWindow();

  const handleSubmit = e => {
    e.preventDefault();

    localStorage.setItem(`country`, values.country);
    navigate(values.destination);
  };

  const query = useStaticQuery(graphql`
    query HomepageCountryQuery {
      wp {
        countries {
          countrySearch {
            countrySelect {
              label
              link
            }
          }
        }
      }
    }
  `);

  const countries = query?.wp?.countries?.countrySearch?.countrySelect;

  const titleSize = () => {
    if (windowMD) {
      return `50px`;
    }
    if (!windowSM) {
      return `30px`;
    }
    return false;
  };

  return (
    <div
      className="homepageBanner"
      style={{ position: `relative`, /* overflow: `hidden`, */ zIndex: 10 }}
    >
      <div
        style={{
          position: `absolute`,
          left: `-15vw`,
          top: 0,
          bottom: 0,
          overflow: `hidden`,
        }}
      >
        <LogoWhite size="50vw" />
      </div>

      <Container>
        <TitleH1 size={titleSize()} margin="15px" line={1} colour={MainColour}>
          {parse(data.title)}
        </TitleH1>
        <p>{parse(data.text)}</p>
        <div
          style={{
            backgroundColor: `#fff`,
            padding: `15px 30px`,
            border: `5px solid ${MainColour}`,
          }}
        >
          <Row align="center" justify="space-between" rowGap="15px">
            <Col
              xl={{ width: `auto` }}
              lg={{ width: `25%` }}
              md={{ width: `30%` }}
              sm={{ width: `37.5%` }}
            >
              <span>I&apos;m applying from</span>
            </Col>
            <Col
              xl={{ grow: true }}
              lg={{ width: `75%` }}
              md={{ width: `70%` }}
              sm={{ width: `62.5%` }}
            >
              <Country field={{ id: 'country', name: 'country' }} />
            </Col>
            <Col
              xl={{ width: `auto` }}
              lg={{ width: `25%` }}
              md={{ width: `30%` }}
              sm={{ width: `37.5%` }}
            >
              <span>and I&apos;m going to</span>
            </Col>
            <Col
              xl={{ grow: true }}
              lg={{ width: `75%` }}
              md={{ width: `70%` }}
              sm={{ width: `62.5%` }}
            >
              <Select
                field={{
                  id: 'destination',
                  name: 'destination',
                  options: countries.map(item => ({
                    value: item.link,
                    option: item.label,
                  })),
                  placeholder: 'Select country...',
                }}
              />
            </Col>
            <Col xl={{ width: `auto` }} className="actionBtn">
              <ButtonHidden onClick={handleSubmit}>
                <ButtonBase
                  style={{
                    minHeight: `62px`,
                    display: `flex`,
                    alignItems: `center`,
                  }}
                  secondary
                >
                  Go
                </ButtonBase>
              </ButtonHidden>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
