import React from 'react';

import FormProvider from '../Form/context';
import HomepageBanner from './banner';
import HomepageColumns from './columns';
import HomepageCta from './cta';
import HomepageLinks from './links';

import './style.scss';

export default function Homepage({ data }) {
  return (
    <>
      <FormProvider>
        <HomepageBanner data={data.header} />
      </FormProvider>
      <HomepageColumns data={data.columns} />
      <HomepageCta data={data.callToAction} />
      <HomepageLinks data={data.popularAdvice} />
    </>
  );
}
