import React from 'react';
import parse from 'html-react-parser';
import { getWindow } from '@web-passion/uikit';

import Container from '../Container';
import Col from '../Container/column';
import Row from '../Container/row';
import Links from '../Links';
import TitleH2 from '../Title/h2';

import loadLibrary from '../Loadable/library';
import { TopRightArrow } from '../Icons/icons';
import { MainColour } from '../../utils/variables';

const Img = loadLibrary('gatsby-image');

export default function HomepageColumns({ data }) {
  const { windowSM } = getWindow();
  return (
    <div
      className="homepageColumns"
      style={{ position: `relative`, zIndex: 5 }}
    >
      <Container>
        <Row justify="space-between" rowGap="30px">
          {data.map((item, i) => {
            const key = `homepageColumn${i}`;
            const { image, content } = item;
            return (
              <Col key={key} className="columnItem" xl={{ width: `32%` }}>
                <Links
                  to={content.link}
                  label={content.title}
                  className="columnHeader"
                  style={{
                    display: `flex`,
                    alignItems: `center`,
                  }}
                >
                  <TitleH2
                    size={windowSM ? '32px' : `24px`}
                    margin="0px"
                    line={1}
                    colour={MainColour}
                  >
                    {content.title}
                  </TitleH2>
                  <TopRightArrow />
                </Links>
                <p>{parse(content.text)}</p>
                <Img
                  image={image?.localFile?.childImageSharp?.gatsbyImageData}
                  alt={content.title}
                  itemProp="image"
                  className="columnImage"
                />
                <Links
                  to={content.link}
                  label={content.action}
                  className="columnAction"
                >
                  <strong>{content.action} &gt;</strong>
                </Links>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
