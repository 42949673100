import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import Homepage from '../components/Homepage';

export default function IndexPage({ data, location }) {
  const prevPage = location.state && location.state.prevPage;

  const currentPage = data.wpPage;

  const pageTitle =
    currentPage.seo && currentPage.seo.title
      ? currentPage.seo.title
      : currentPage.title;

  return (
    <Layout
      title={pageTitle}
      seo={currentPage.seo}
      location="/"
      padding="0"
      prevPage={prevPage}
    >
      <Homepage data={currentPage.homepage} />
    </Layout>
  );
}

export const HomepageData = graphql`
  query HomeQuery {
    wpPage(slug: { eq: "home" }) {
      title
      content
      ...PageSeo
      ...HomepageContent
    }
  }
`;
