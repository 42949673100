import React from 'react';
import Container from '../Container';
import Cta from '../Cta';

export default function HomepageCta({ data }) {
  const { content, button } = data;
  return (
    <Container>
      <Cta
        data={{
          title: content.title,
          content: content.text,
          buttonLink: button.link,
          buttonText: button.text,
        }}
      />
    </Container>
  );
}
